@import "./reset";
@import "./table";
@import "./antd-fix";
@import "./antd-utils";

#root {
  height: 100%;
}

a {
  color: #37aeb9;
  cursor: pointer !important;
}

.hover-show-target {
  display: none !important;
}

.hover-show:hover .hover-show-target {
  display: block !important;
}

.link {
  color: #37aeb9;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.btn-link {
  color: #37aeb9;
  cursor: pointer;
}

.btn-delete {
  color: #eb6841;
  cursor: pointer;
}

.icon-bind {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url("assets/icons/icon-bind.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-bind-hover {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url("assets/icons/icon-bind-hover.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-import {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-import.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-help {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-help.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-service {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-service.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-comment {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-comment.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-close {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-close.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-close-outline {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-close-outline.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-doc {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-doc.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-version {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-version.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-schema {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-schema.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-add-schema {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-add-schema.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit-active {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-edit-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-copy-schema {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-copy-schema.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-copy-schema-active {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-copy-schema-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-delete-active {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-delete-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-delete {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-delete.png") 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-edit-small {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon-edit.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-edit.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit-primary-small {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon-edit-primary.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-edit-primary {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-edit-primary.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-delete-primary {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-delete-primary.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-phone {
  display: inline-block;
  width: 15px;
  height: 18px;
  background: transparent url("assets/icons/icon-phone.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-tip {
  display: inline-block;
  width: 62px;
  height: 24px;
  background: transparent url("assets/icons/icon-tip.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-normal-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-normal-user.png") 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-vip-user {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-vip-user.png") 0 0 no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.icon-invite {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url("assets/icons/icon-invite.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-person-phone {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-person-phone.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-person-school {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-person-school.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-person-location {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-person-location.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-export-tip {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon-export-tip.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-vip {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-vip.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-video {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-video.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-weixin {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-weixin.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-qywx {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-qywx.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-qywx-white {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-qywx.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-weixin-active {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: transparent url("assets/icons/icon-weixin-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-white-list {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-white-list.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-required {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: transparent url("assets/icons/icon-required.png") 0 0 no-repeat;
  background-size: 100%;
  position: absolute;
  top: 2px;
  right: -10px;
}

.icon-question-active {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon-question-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-time-primary {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: transparent url("assets/icons/icon-time-primary.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-auto-time-primary {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url("assets/icons/icon-auto-time-primary.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-delete-red {
  display: inline-block;
  width: 18px;
  height: 18px;
  background: transparent url("assets/icons/icon-delete-red.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-save {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon-save.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-vip-down {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: transparent url("assets/icons/icon_vip_down.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-release {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-release.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-release-active {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-release-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-no-release {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-no-release.png") 0 0 no-repeat;
  background-size: 100%;
}

.icon-no-release-active {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: transparent url("assets/icons/icon-no-release-active.png") 0 0 no-repeat;
  background-size: 100%;
}

.ellipsis1 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.placeholder-primary {
  input::placeholder {
    color: #37aeb9 !important;
  }
}
