.ant-card {
  border: 0;
}

.ant-btn.ant-btn-link[class*=" ant-btn"] {
  &:hover {
    color: inherit;
  }
}

.ant-tour {
  .ant-tour-arrow {
    //&::before {
    //  background: #1677ff !important;
    //}
    //
    //&::after {
    //  background: #1677ff;
    //}
  }

  .ant-tour-inner {
    //background-color: #1677ff !important;
  }

  .ant-tour-buttons {
    button {
      //color: #1677ff !important;
    }
  }
}

.ant-table-wrapper {
  .ant-table {
    .ant-table-thead {
      tr {
        .ant-table-cell {
          background: #f9fcff;
        }
      }
    }

    .ant-table-tbody > tr > td.ant-table-cell.ant-table-cell-row-hover {
      background: #f9fcff;
    }

    .ant-table-expanded-row {
      .ant-table-cell {
        background: #fff !important;
      }
    }
  }
}
